.StepNavigator {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #8d9bd7;
}

.StepNavigator .row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.StepNavigator h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #8d9bd7;
}

.StepNavigator__step {
  width: 40px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(141, 155, 215, 0.2);
}

.StepNavigator__step.passed {
  background-color: #8d9bd7;
}

.StepNavigator__step.passed.current {
  background-color: #ffffff !important;
}
