.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

#spike__container {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  background-color: #060a1b;
}

#spikeCanvas {
  top: 20px;
  position: relative;
}

.text_content {
  width: 100%;
}

.text_content div {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #292e45;
  border-top: 1px solid #060a1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transition: 0.3s;
}

@media (max-width: 1200px) {
  #spikeCanvas {
    width: 800px;
  }
}

@media (max-width: 600px) {
  #spikeCanvas {
    width: 800px;
  }
}
