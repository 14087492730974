.checkbox-wrapper {
  background-color: initial;
  border: 1px solid #292e45;
  border-radius: 10px;
  cursor: pointer !important;
  height: auto;
  outline: none;
  display: flex;
  padding: 20px;
  width: 100%;
  min-height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.checkbox-wrapper.checked {
  background-color: #292e45;
}

.labelWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.labelWithIcon img {
  height: 40px;
  width: 40px;
  min-height: 40px;
  max-width: 40px;
}

.checkbox-wrapper label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  pointer-events: none;
  font-weight: 600;
}

.checkbox-wrapper label .hint {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.3);
}

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  display: none;
  /* creating a custom design */
  width: 100%;
  height: auto;
  outline: none;
  cursor: pointer;
}

.checkbox {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #060a1b;
  border: 1px solid #292e45;
  transition: 0.3s;
}

.checkbox.round {
  border-radius: 100%;
}

.checkbox.checked {
  background-image: url("data:image/svg+xml;utf8, <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.99997 13.586L4.70697 10.293L3.29297 11.707L7.99997 16.414L17.707 6.70697L16.293 5.29297L7.99997 13.586Z' fill='white'/></svg>");
  background-size: contain;
  border-color: #04befe;
  background-color: #04befe;
}
