* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background: #141829;
  color: #ffffff;
  /* background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color); */
  font-family: "Roboto";
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  padding: 20px;
}
